import ls from "local-storage";
import { ChangeEvent } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../../Images/logo.svg";
import { LoginRequest } from "../../Types/apiRequest";
import { LoginResponse } from "../../Types/apiResponses";
import { login } from "../../Utils/apiUtils";
import { parseFormData } from "../../Utils/generalUtils";

export default function LoginPage() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  /**If request ok do a recheck for token, which redirects on success */
  const loginMutation = useMutation(login, {
    onSuccess: (response) => {
      const data = response.data as LoginResponse;
      ls("token", data.authorization.token);
      const now = new Date();
      const expireTime =
        now.getTime() + data.authorization.expires_in * 1000; // convert TTL from seconds to milliseconds
      const expireDate = new Date(expireTime);
      ls("token_expire", expireDate);
      ls("user", data.user);
      //if settings under user.data is set then parse it to local storage under the keys it has
      // if (data.user.settings) {
      //   setUserSettings(JSON.parse(data.user.settings));
      // }
      queryClient.invalidateQueries("token");
      navigate("/listat");
    },
    onError: (error: any) => {
      Swal.fire({
        title: "Kirjautuminen epäonnistui",
        text: "Tarkista käyttäjätunnus ja salasana",
        icon: "error",
        confirmButtonText: "Ok",
      });
    },
  });

  const handleSumbit = (event: ChangeEvent<HTMLFormElement>) => {
    const formContent = parseFormData(event) as LoginRequest;
    loginMutation.mutate({
      password: formContent.password,
      username: formContent.username,
    });
  };

  return (
    <div className="login-bg">
      <div className="m-0 p-0 position-fixed top-50 start-50 translate-middle w-100">
        <div className="row justify-content-center">
          <div className="shadow-md login-page-input-container bg-white col-10 col-sm-6 col-md-5 col-xl-3 text-center px-5 py-3">
            <h5 className="m-0 p-0 text-center mb-3">
              Bongariliitto
            </h5>
            <div className="w-100 d-flex justify-content-center align-items-center mb-4">
              <img
                src={logo}
                alt="Bongariliitto logo"
                width={60}
                height={60}
              />
              <h1
                style={{ fontSize: "4em" }}
                className="m-0 p-0"
              >
                Pinnari
              </h1>
            </div>
            <div className="fluid">
              <form
                method="post"
                id="login-form"
                onSubmit={handleSumbit}
              >
                <label className="w-100">
                  <p className="text-start m-0 p-0">
                    Käyttäjänimi:
                  </p>
                  <input
                    className="w-100 p-1 login-input"
                    type="text"
                    id="username"
                    name="username"
                  />
                </label>
                <label className="w-100">
                  <p className="text-start m-0 p-0">Salasana:</p>
                  <input
                    className="w-100 p-1 login-input"
                    type="password"
                    id="password"
                    name="password"
                  />
                </label>

                <div className="my-2">
                  <a href={"/vaihda-salasana"}>
                    Unohtuiko salasana?
                  </a>
                </div>

                <div className="my-2">
                  <a
                    href={
                      "https://bongariliitto.fi/index.php/liittyminen/"
                    }
                  >
                    Mistä saan käyttäjän?
                  </a>
                </div>
                <button className="w-100" type="submit">
                  Kirjaudu
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
