import { remove as lsRemove } from "local-storage";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { createObservationBody } from "../Pages/ObservationEditorPage/observationUtils";
import {
  AssociationIndexResponse,
  ContinentIndexResponse,
  CountryIndexResponse,
  ExtendedTaxonOptionResponse,
  MunicipalityIndexResponse,
} from "../Types/apiResponses";
import {
  postObservation,
  updateObservation,
} from "../Utils/apiUtils";
import useLocalStorageValue from "./useLocalStorageValue";
import useOptions from "./useOptions";

/**
 *  Hook to enclapsulate the bulk of utility functions needed to
 *  provided all functionality for observation editor (mostly needed for search param manipulation)
 */
export default function useObservationEditorUtilities() {
  /** ----------------------[STATE/HOOKS/CONSTANTS]-------------------------------------- */
  const OWN_OBSERVATION_QUERY_KEY = "own-observations-all";
  const OWN_OBSERVATION_QUERY_KEY_TAXON_ON =
    "own-observations-taxon-on";
  const [searchParams, setSearchParams] = useSearchParams();
  /** Inputs we don't want to clear on navigation or submit */
  const [noClearKeys, setNoClearKeys] =
    useLocalStorageValue<any>("no-clear-inputs", {});
  const queryClient = useQueryClient();
  const location = useLocation();
  const {
    getCollectionItemFieldById,
    getCollectionItems,
    getCollectionItemField,
    isReady,
  } = useOptions();
  const [submitting, setSubmitting] = useState(false);

  /**-----------------------[MUTATIONS/QUERIES]---------------------------------*/
  /** Needs to be declared before used */
  const handleObservationMutationError = (error: string) => {
    let message = "Jotain meni pieleen";

    switch (error) {
      case "BAD_AREA":
        message =
          "Sijainti valinnoissa on virhe, tarkasta kentät";
        break;
      case "NOT_FIN":
      case "NOT_WP":
        message = "Valittu laji ei sovellu valitulle alueelle.";
        break;
      case "BAD_DATE":
        message =
          "Päivämäärä ei ole oikeassa muodossa, lataa havaintosivu uudestaan";
        break;
      case "PRIMARY_FAILED":
      case "SECONDARY_FAILED":
        message =
          "Havainnon tallennus epäonnistui. Yritä uudelleen.";
        break;
      default:
        break;
    }

    raiseAlert(message);
  };

  const createObservationMutation = useMutation(
    postObservation,
    {
      onSuccess: () => {
        setSubmitting(false);
        queryClient.invalidateQueries(OWN_OBSERVATION_QUERY_KEY);
        /** invalidate the list details */
        queryClient.invalidateQueries([
          "list-observation-details",
          "*",
        ]);
        queryClient.invalidateQueries([
          "list-species-details",
          "*",
        ]);
      },
      onError: (err: any) => {
        setSubmitting(false);
        //error message handling (take message from response)
        handleObservationMutationError(
          err.response?.data.message
        );
      },
    }
  );

  const updateObservationMutation = useMutation(
    updateObservation,
    {
      onSuccess: () => {
        setSubmitting(false);
        queryClient.invalidateQueries(OWN_OBSERVATION_QUERY_KEY);
        queryClient.invalidateQueries(
          OWN_OBSERVATION_QUERY_KEY_TAXON_ON
        );
        queryClient.invalidateQueries([
          "list-observation-details",
          "*",
        ]);
        queryClient.invalidateQueries([
          "list-species-details",
          "*",
        ]);
        searchParams.delete("edit");
        if (searchParams.get("taxon_on")) {
          searchParams.set("taxon_on", "1");
        }
        setSearchParams(searchParams, { replace: true });
      },
      onError: (err: any) => {
        setSubmitting(false);
        //error message handling (take message from response)
        handleObservationMutationError(
          err.response?.data.message
        );
      },
    }
  );

  /**-----------------------[UTILITY FUNCTIONS]-------------------------------- */
  /**  Initalize the search params state like customer asked for */
  const initPageParams = () => {
    if (!searchParams.get("edit")) {
      Object.keys(noClearKeys).forEach((key) => {
        if (noClearKeys && noClearKeys[key] !== "on") {
          searchParams.set(key, noClearKeys[key]);
        }
      });
    }
    /** Remove the back navigation bubble gum solution from forchig movement on page*/
    lsRemove("last-open-list");
  };

  /** Allow clearing all keys when editing */
  const initPageOnEdit = () => {
    if (searchParams.get("edit")) {
      setNoClearKeys({});
    }
  };

  /** Called when user clicks the lock icon next to input field (doesn't want it to be cleared) */
  const handleToggleNoClear = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    /** lock div element id === input element name */
    const element = e.currentTarget.id;
    if (noClearKeys[element]) {
      delete noClearKeys[element];
      setNoClearKeys(noClearKeys);
    } else {
      const value = "on";
      setNoClearKeys({
        ...noClearKeys,
        [element]: value,
      });
    }
  };

  const isInNoClear = (name: string) => {
    return noClearKeys[name] !== undefined;
  };

  const addKeyToNoClearMultiple = (
    values: Array<{
      key: string;
      value: string | null | undefined;
    }>
  ) => {
    const newClaerKeys = { ...noClearKeys };
    values.forEach(({ key, value }) => {
      if (!value || value === "") {
        delete newClaerKeys[key];
      } else {
        newClaerKeys[key] = value;
      }
    });
    setNoClearKeys(newClaerKeys);
  };

  const handleSpeciesLanguageChange = (nextLanguage: string) => {
    /** Causes problems if we don't reset */
    searchParams.delete("species");

    if (nextLanguage === "") {
      searchParams.delete("language");
    }
    if (nextLanguage === searchParams.get("language")) {
      searchParams.delete("language");
    } else {
      searchParams.set("language", nextLanguage);
    }
    setSearchParams(searchParams, { replace: true });
  };

  /** Handle addition of keywords, saved as a string to url params */
  const parseKeywordIdsFromParams = () => {
    const keywordString = searchParams.get("keywords");
    if (!keywordString) return [];
    const keywordIds = keywordString.split(",");
    return keywordIds;
  };

  const handleKeywordChange = (keywordId: string) => {
    if (keywordId && keywordId !== "") {
      const keywords = parseKeywordIdsFromParams();
      if (keywords.includes(keywordId.toString())) {
        raiseAlert("Asiasana on jo lisätty");
        return;
      }
      keywords.push(keywordId);
      searchParams.set("keywords", keywords.join(","));
      setSearchParams(searchParams, { replace: true });
    }
  };

  /** Handler for those keywords bubbles (remove on click) */
  const handleKeywordClick = (e: React.MouseEvent<any>) => {
    const target = e.currentTarget.id;
    const keywords = parseKeywordIdsFromParams();
    const newKeywords = keywords.filter(
      (id) => id && id !== target
    );
    if (!isEmpty(newKeywords)) {
      searchParams.set("keywords", newKeywords.join(","));
    } else {
      searchParams.delete("keywords");
    }
    setSearchParams(searchParams, { replace: true });
  };

  /**
   * Forces people to use right hierarchy, is bit annoying and validates nothing but
   * it's wanted this way
   */
  const forceBackFill = (
    name:
      | "continent"
      | "country"
      | "association"
      | "municipality"
      | "area",
    value: string | undefined | null,
    currentParams: URLSearchParams
  ) => {
    const nextParams = currentParams;
    let fk_id = undefined;
    let nextName = name;
    if (!value) return nextParams;
    switch (nextName) {
      case "municipality":
        fk_id =
          getCollectionItemFieldById<MunicipalityIndexResponse>(
            "municipalities",
            "association_id",
            value
          );
        nextParams.set("association", fk_id);
        nextName = "association";
        break;
      case "association":
        fk_id =
          getCollectionItemFieldById<AssociationIndexResponse>(
            "associations",
            "country_id",
            value
          );
        nextParams.set("country", fk_id);
        nextName = "country";
        break;
      case "country":
        fk_id = getCollectionItemFieldById<CountryIndexResponse>(
          "countries",
          "continent_id",
          value
        );
        nextParams.set("continent", fk_id);
        nextName = "continent";
        break;
      case "continent":
        fk_id =
          getCollectionItemFieldById<ContinentIndexResponse>(
            "continents",
            "wp",
            value
          );
        nextParams.set("area", fk_id ? "1" : "2");
        nextName = "area";
        break;
      default:
        break;
    }
    if (!fk_id) {
      raiseAlert(
        `Kentässä [${name}] löytyi arvo jolla ei ole linkkiä ylempään tasoon (yhdistys, maa, maanosa tai alue)
        valitettavasti tälläisissä tilanteissa täyttöä ei voida suorittaa. Otathan yhteyden järjestelmän ylläpitäjiin, jotta virheelliset arvot poistetaan
      `
      );
      /** Delete all fields and return */
      nextParams.delete("area");
      nextParams.delete("continent");
      nextParams.delete("country");
      nextParams.delete("association");
      nextParams.delete("municipality");
      return nextParams;
    }
    if (fk_id && nextName !== "area") {
      forceBackFill(nextName, fk_id, nextParams);
    }
    return nextParams;
  };

  /** All the side effects we want the user to experience */
  const handleAreaLogic = (
    name:
      | "area"
      | "continent"
      | "country"
      | "association"
      | "municipality",
    value: string | undefined | null,
    searchParams: URLSearchParams
  ) => {
    /** No need to do anything */
    switch (name) {
      case "area":
        searchParams.delete("continent");
        searchParams.delete("country");
        searchParams.delete("association");
        searchParams.delete("municipality");
        break;
      case "continent":
        searchParams.delete("country");
        searchParams.delete("association");
        searchParams.delete("municipality");
        break;
      case "country":
        searchParams.delete("association");
        searchParams.delete("municipality");
        break;
      case "association":
        searchParams.delete("municipality");
        break;
      case "municipality":
        searchParams = forceBackFill(
          "municipality",
          value,
          searchParams
        );
    }
  };

  /** Wrapper to make setting search parameters easier */
  const handleChangeParam = (
    name: string,
    value: string | undefined | null
  ) => {
    if (value && value !== "") {
      searchParams.set(name, value);
    } else {
      searchParams.delete(name);
    }
    if (
      name === "area" ||
      name === "continent" ||
      name === "country" ||
      name === "association" ||
      name === "municipality"
    ) {
      handleAreaLogic(name, value, searchParams);
    }
    /** Replace history with new search params */
    setSearchParams(searchParams, {
      replace: true,
    });
    /**Add all area params to noClear */
    if (
      name === "area" ||
      name === "continent" ||
      name === "country" ||
      name === "association" ||
      name === "municipality"
    ) {
      addKeyToNoClearMultiple([
        {
          key: "area",
          value: searchParams.get("area"),
        },
        {
          key: "continent",
          value: searchParams.get("continent"),
        },
        {
          key: "country",
          value: searchParams.get("country"),
        },
        {
          key: "association",
          value: searchParams.get("association"),
        },
        {
          key: "municipality",
          value: searchParams.get("municipality"),
        },
      ]);
    }
  };

  /** Fetch finnish ioc for free form input */
  const getSpeciesNameById = (id: string) => {
    if (!id) return "";
    return getCollectionItemFieldById<ExtendedTaxonOptionResponse>(
      "taxons",
      "ioc_finnish",
      id
    );
  };

  /** Basicly get taxons that can be seen in the area and are valid for userInput */
  const getPossibleTaxonOptions = (nameInputText: string) => {
    const selectedLanguage = searchParams.get("language");
    let possibleTaxons: ExtendedTaxonOptionResponse[] = [];

    if (
      nameInputText.length > 2 &&
      selectedLanguage &&
      (selectedLanguage === "ioc_finnish" ||
        selectedLanguage === "ioc_english" ||
        selectedLanguage === "ioc_scientific" ||
        selectedLanguage === "scientific_abbreviation")
    ) {
      let wp: null | number = null;
      let fin = 0;

      if (searchParams.get("area")) {
        wp = searchParams.get("area") === "1" ? 1 : null;
      }

      if (searchParams.get("country") === "162") {
        fin = 1;
      }

      /** will fix at some point, very ugly*/
      const areaLimit = (item: any) => {
        if (fin === 1) {
          return item["fin"] === 1;
        }
        if (wp === 1) {
          return item["wp"] === 1;
        }
        return true; // if neither 'fin' or 'wp' criteria match, it's ok to return all
      };

      const possibleSpecies =
        getCollectionItems<ExtendedTaxonOptionResponse>(
          "taxons",
          (item) =>
            item[selectedLanguage] &&
            item[selectedLanguage]
              .toLowerCase()
              .startsWith(nameInputText.toLowerCase()) &&
            areaLimit(item)
        );
      if (possibleSpecies) {
        possibleTaxons = possibleSpecies;
      }
      //order by name length
      possibleTaxons.sort((a, b) => {
        if (
          a[selectedLanguage] &&
          b[selectedLanguage] &&
          a[selectedLanguage].length > b[selectedLanguage].length
        ) {
          return 1;
        }
        return -1;
      });
    }
    return possibleTaxons;
  };

  const raiseAlert = (message: string) => {
    /** Show small error window with ok with only esc as close key*/
    Swal.fire({
      icon: "error",
      title: "Huom!",
      text: message,
      confirmButtonText: "Ok",
      showConfirmButton: true,
      allowEscapeKey: true,
      allowOutsideClick: true,
      allowEnterKey: false,
      customClass: {
        icon: "d-none",
      },
    });
  };

  /** Go to element once timeout expires */
  const focusAfterTimeOut = (
    elementId: string,
    timeout: number
  ) => {
    setTimeout(() => {
      const element = document.getElementsByName(elementId)[0];
      if (element) element.focus();
    }, timeout);
  };

  /** Calls underlying mutations with requested logic */
  const handleSubmitObservation = (
    notClearedInputFields: string[]
  ) => {
    const editId = searchParams.get("edit");
    const speciesId = searchParams.get("species");
    const additional_info = searchParams.get("additional_info") || "";
    const date = searchParams.get("date") || "";
    const keywords = searchParams.get("keywords") || "";
  
    if (!speciesId) {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
          e.preventDefault();
          e.stopPropagation();
          Swal.close();
        }
      };
  
      document.addEventListener("keydown", handleKeyDown, true);
  
      Swal.fire({
        icon: "error",
        title: "Huom!",
        text: "Lajia ei ole asetettu, et voi lähettää havaintoa",
        confirmButtonText: "Ok",
        showConfirmButton: true,
        allowEscapeKey: true,
        allowOutsideClick: true,
        allowEnterKey: true,
        customClass: {
          icon: "d-none",
        },
      }).then(() => {
        document.removeEventListener("keydown", handleKeyDown, true);
      });
      return;
    }
  
    setSubmitting(true);
    if (editId) {
      updateObservationMutation.mutate({
        body: createObservationBody(
          location.search,
          speciesId,
          additional_info,
          date,
          keywords
        ),
        id: editId,
      });
    } else {
      createObservationMutation.mutate(
        createObservationBody(
          location.search,
          speciesId,
          additional_info,
          date,
          keywords
        )
      );
    }
  
    const defaultClearedFields = [
      "date",
      "keywords", 
      "species",
      "additional_info",
    ];
  
    const clearedFields = defaultClearedFields.filter(
      (field) => !notClearedInputFields.includes(field)
    );
    clearedFields.forEach((field) => {
      searchParams.delete(field);
    });
    setSearchParams(searchParams, {
      replace: true,
    });
  
    const focusSpecies = notClearedInputFields.includes("species");
    focusAfterTimeOut(
      focusSpecies ? "additional_info" : "species",
      300
    );
  };

  /** Handles searching for taxon when no language is selected */
  const handleFreeFormTaxonChange = (
    value: string | undefined | null
  ) => {
    if (!value || value === "") {
      searchParams.delete("species");
      setSearchParams(searchParams, {
        replace: true,
      });
      return;
    }
    if (value !== "") {
      const id =
        getCollectionItemField<ExtendedTaxonOptionResponse>(
          "taxons",
          "id",
          (item) => {
            if (
              item.ioc_finnish &&
              item.ioc_finnish.toLowerCase() ===
                value.toLowerCase()
            ) {
              return true;
            }
            if (
              item.ioc_english &&
              item.ioc_english.toLowerCase() ===
                value.toLowerCase()
            ) {
              return true;
            }
            if (
              item.ioc_scientific &&
              item.ioc_scientific.toLowerCase() ===
                value.toLowerCase()
            ) {
              return true;
            }
            if (
              item.scientific_abbreviation &&
              item.scientific_abbreviation.toLowerCase() ===
                value.toLowerCase()
            ) {
              return true;
            }
            return false;
          }
        );
      if (!id) {
        searchParams.delete("species");
        setSearchParams(searchParams, {
          replace: true,
        });
        return;
      } else {
        searchParams.set("species", id.toString());
        setSearchParams(searchParams, {
          replace: true,
        });
        return;
      }
    }
  };

  const optionsReady = () => {
    return isReady;
  };

  /** Export all functions */
  return {
    raiseAlert,
    handleChangeParam,
    optionsReady,
    handleSubmitObservation,
    getPossibleTaxonOptions,
    getSpeciesNameById,
    focusAfterTimeOut,
    initPageOnEdit,
    initPageParams,
    handleSpeciesLanguageChange,
    handleFreeFormTaxonChange,
    getCollectionItems,
    getCollectionItemFieldById,
    handleKeywordChange,
    handleKeywordClick,
    parseKeywordIdsFromParams,
    isInNoClear,
    handleToggleNoClear,
    noClearKeys,
    submitting,
  };
}
